.sidebar {
  width: 200px;
  transition: width 0.3s ease-in-out;
  overflow: auto;
  background-color: rgb(10, 25, 41);
  padding-top: 28px;
}
.sidebarClosed {
  composes: sidebar;
  transition: width 0.3s ease-in-out;
  width: 50px;
}
.sideitem {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  cursor: pointer;
  color: #b2bac2;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
}
.linkText {
  padding-left: 16px;
}

.sideitem:hover {
  background-color: #7fa2c71c;
}
.menuBtn {
  align-self: center;
  align-self: flex-start;
  justify-self: flex-end;
  color: #b2bac2;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 20px;
}
